import { getUrlParameter } from '../../fdpq-widget/utils/helperUtil';

export const PAGE_NAME = {
  TRADEIN_VEHICLE_SELECTOR: 'TRADEIN_VEHICLE_SELECTOR',
  VEHICLE_SELECTOR: 'VEHICLE_SELECTOR',
  DEALER_SELECTOR: 'DEALER_SELECTOR',
  CONTACT_FORM: 'CONTACT_FORM',
  THANK_YOU_PAGE: 'THANK_YOU_PAGE',
};

export const HOST_WEBSITES = {
  KBB: 'kbb',
  ATC: 'atc'
};

export const VEHICLE_LISTING_TYPE = {
  NEW: 'new',
  USED: 'used',
  CPO: 'cpo',
  TRADEIN: 'tradein',
};

export const WIDGET_LABEL = {
  NEW_CAR: 'ncpq',
  USED_CAR: 'ucpq',
  CPO_PRICE: 'cpopq',
  TRADEIN_LEAD: 'tilp',
};

export const DROPDOWN_TYPE = {
  YEAR: 'year',
  MAKE: 'make',
  MODEL: 'model',
  TRIM: 'trim',
};

export const RESPONSE_TYPE = {
  SUBMIT_LEAD_SUCCESS: 'SUBMIT_LEAD_SUCCESS'
};

export const HEADER_TEXT_VALUES = {
  BLANK_HEADER: 'Free Dealer Price Quote',
};

export const FLOODLIGHT_KEYS = {
  YEAR: 'FLOODLIGHT_YEAR',
  MAKE: 'FLOODLIGHT_MAKE',
  MODEL: 'FLOODLIGHT_MODEL',
  TRIM: 'FLOODLIGHT_TRIM',
  SEND_TO: 'send_to',
};

export const DEFAULT_VALUE_EVENT_CODE = '658E';
export const TRADEIN_LEAD_VALUE_EVENT_CODE = '143B';
export const DEFAULT_DEALERSHIP_EVENT_SOURCE = 'dealership';
export const PSX_EVENT_SOURCE = 'button';
export const DEFAULT_FEATURE = 'FDPQ Lead';
export const PSX_FEATURE = 'Psx Lead';
export const ATC_BRAND_NAME = 'Autotrader';
export const KBB_BRAND_NAME = 'KBB';
export const KBB_BRAND = 'kbb.com';
export const ATC_BRAND = 'autotrader.com';

export const getPixallEventData = (vehicleListingType, isPSX = false) => {
  const pageId = getUrlParameter('pageid') || '';
  const pageType = getUrlParameter('pagetype') || '';
  const hostName = getUrlParameter('origin') || '';
  const isKbb = hostName?.includes(KBB_BRAND);
  const assistChannel = isKbb ? KBB_BRAND_NAME : ATC_BRAND_NAME;
  const eventSource = isPSX ? PSX_EVENT_SOURCE : DEFAULT_DEALERSHIP_EVENT_SOURCE;
  const feature = isPSX ? PSX_FEATURE : DEFAULT_FEATURE;
  let valueEventCode = DEFAULT_VALUE_EVENT_CODE;
  let listingType = WIDGET_LABEL.NEW_CAR;
  

  switch(vehicleListingType){
    case VEHICLE_LISTING_TYPE.NEW:
      listingType = WIDGET_LABEL.NEW_CAR;
      break;
    case VEHICLE_LISTING_TYPE.USED:
      listingType = WIDGET_LABEL.USED_CAR;
      break;
    case VEHICLE_LISTING_TYPE.CPO:
      listingType = WIDGET_LABEL.CPO_PRICE;
      break;
    case VEHICLE_LISTING_TYPE.TRADEIN:
      listingType = WIDGET_LABEL.TRADEIN_LEAD;
      break; 
    default:
      listingType = WIDGET_LABEL.NEW_CAR;
  }

  if (listingType === WIDGET_LABEL.TRADEIN_LEAD) {
    valueEventCode = TRADEIN_LEAD_VALUE_EVENT_CODE;
  };

  let specs = {};
  specs.EVENT_TYPE_CLICK = 'click';
  specs.LABEL_STEP_CLICK = `cta::${listingType}::get-my-free-quotes`;
  specs.EVENT_TYPE_IMPRESSION = 'impression';
  specs.LABEL_STEP_IMPRESSION = `lead::${listingType}`;
  specs.EVENT_FIELD_GOAL = 'FDPQ Lead Submission';
  specs.LABEL_STEP_CONTACT = `cta::${listingType}::send`;
  specs.CDL_VALUE_EVENT_CODE = valueEventCode;
  specs.EVENT_SOURCE = eventSource;
  specs.PSX_LABEL = 'sell-my-car';
  specs.FEATURE = feature;
  specs.ASSIST_CHANNEL = assistChannel;
  specs.ASSIST_PRODUCT = DEFAULT_FEATURE;
  specs.ASSIST_SUBCHANNEL = DEFAULT_FEATURE;
  specs.PAGE_TYPE = pageType;
  specs.PAGE_ID = pageId;

  return specs;
};

