/** @jsx jsx */
import { useEffect  } from 'react';
import { jsx } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import { VEHICLE_LISTING_TYPE } from '@packages/fdpq-core/constants/app';
import { connect } from 'react-redux';

import {
  Column,
  Description,
  FlexCell,
  FlexContainer,
  FlexRow,
  Footer,
} from '@packages/fdpq-common';
import { widgetSize } from '@packages/fdpq-common/constants/ui';
import DealerList from '../../fdpq-dealer-selector/components/DealerList';
import KeylineContainer from '../../fdpq-common/components/KeylineContainer';
import { text } from '../../fdpq-core/config/vars';
import { mapStateToProps, mapDispatchToProps } from '../containers/ThankYouPage';
import { getPixallEventData } from '@packages/fdpq-core/constants/app';
import { sendEventPixall, createCoxAutoCorrelationId } from '@packages/fdpq-common/analytics/pixall';
import Button from '@argo/ui-elements/Button';
import Image from '@argo/ui-elements/Image';
import commonConfig from '@packages/fdpq-core/config/common';
import Heading from '@argo/ui-elements/Heading';
import styled from '@emotion/styled';
import Paragraph from '@argo/ui-elements/Paragraph';


const ThankYouPage = ({
  onNextPageSelected,
  onButtonPress,
  theme,
  selectedDealers,
  listingTypeConfig,
  leadpartnerfranchiseid,
  leadFranchiseId,
  zipCode,
}) => {
  
const ATBreakLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.thankYouPage.ATBreakLineColor};

  @media (max-width: 480px) {
    display: none;
  }
`;  

const PSXContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -10px;
  margin-left: 24px;
  margin-right: 24px;

  @media (max-width: 480px) {
    flex-direction: column;
    margin-left: 16px;
  }
`;

const PSXContentContainer = styled.div`
  width: 80%;
`;

const PSXImageContainer = styled.div`
  margin-left: 40px;

  @media (max-width: 480px) {
    margin-left: 0px;
  }
`;

  const coxAutoCorrelationId = createCoxAutoCorrelationId();
  const pixallData = getPixallEventData(listingTypeConfig.vehicleListingType);
  const eventData = {
    eventType: pixallData.EVENT_TYPE_IMPRESSION,
    eventSource: pixallData.EVENT_SOURCE,
    label: pixallData.LABEL_STEP_IMPRESSION,
    assistChannel: pixallData.ASSIST_CHANNEL,
    assistProduct: pixallData.ASSIST_PRODUCT,
    assistSubchannel: pixallData.ASSIST_SUBCHANNEL,
    pageType: pixallData.PAGE_TYPE,
    pageId: pixallData.PAGE_ID,
  }

  const psxPixallData = getPixallEventData(listingTypeConfig.vehicleListingType, true);
  const psxEventData = {
    eventType: psxPixallData.EVENT_TYPE_CLICK,
    eventSource: psxPixallData.EVENT_SOURCE,
    label: psxPixallData.PSX_LABEL,
    feature: psxPixallData.FEATURE,
    assistChannel: pixallData.ASSIST_CHANNEL,
    pageType: pixallData.PAGE_TYPE,
    pageId: pixallData.PAGE_ID,
  }

  const vehicleData = { 
    zipCode,
  };

  const getAnotherQuoteButton = (
    <Button
      id="getAnotherQuote"
      buttonType='tertiary'
      onClick={(e) => {
        onButtonPress();
        onNextPageSelected(e);
      }}
      text={text.global.thankYouView.getAnotherQuoteText}
      sizeType='large'
      width='186px'
      height='48px'
      style={{ border: `1px solid ${theme.thankYouPage.ATBtnBorderColor}`}}
    />
  )

  useEffect(() => {
    sendEventPixall(coxAutoCorrelationId, eventData, vehicleData);
  }, []);

  const handleSellCarClick = () => {
    try {
      if (window.self !== window.top) {
        // if in an iframe, redirect the parent window
        window.parent.location.href = commonConfig.psxCTAUrl;
      } else {
        // if not in an iframe, redirect current window
        window.location.href = commonConfig.psxCTAUrl;
      }
      
      sendEventPixall(coxAutoCorrelationId, psxEventData, vehicleData);
    } catch (error) {
      console.error('Navigation error:', error);
      window.location.href = commonConfig.psxCTAUrl;
    }
  }

  return (
    <Column id="test-thank-you-page">
      <FlexContainer>
        <KeylineContainer style={{ paddingBottom: listingTypeConfig.vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN ? '0px' : '16px' }}>
          <FlexRow>
            <FlexCell fullWidth={true}>
              <Description
                title={text.global.thankYouView.titleText}
                subtitle={text.global.thankYouView.subtitleText}
              />
            </FlexCell>
          </FlexRow>
        </KeylineContainer>
        <FlexRow style={{ marginTop: theme.widgetSize === widgetSize.SMALL ? '10px' : '0', paddingRight: '80px' }}>
          <DealerList displayDealerList={selectedDealers} vehicleListingTypeConfig={listingTypeConfig} />
        </FlexRow>
        <FlexRow>
          <div>
            {(leadFranchiseId.length === 0 && leadpartnerfranchiseid.length === 0) ? getAnotherQuoteButton : ''}
          </div>
        </FlexRow>
        <ATBreakLine />

      </FlexContainer>
      <Footer>
        <PSXContainer>
          <PSXContentContainer>
            <Heading headingT ag="h2" headingName="ContentHeadingExtra" enableBakeTitle={false}>
              {text.global.thankYouView.psxTitleText}
            </Heading>
            <Paragraph
              defaultMargin={true}
              textAlign="left"
              type="body"
              id="description-subtitle"
            >
              {text.global.thankYouView.psxSubTitleText}
            </Paragraph>
            <Button
              id="sellCurrentCar"
              buttonType='secondary'
              onClick={handleSellCarClick}
              text={text.global.thankYouView.sellCurrentCarText}
              sizeType='large'
              width='192px'
              height='48px'
              style={{ background: `{${theme.thankYouPage.ATBtnBackgroundColor}}`, marginTop: '8px'}}
            />
          </PSXContentContainer>
          <PSXImageContainer>
            <Image src={`${commonConfig.fdpqFileCdnBaseUrl}/Shop-Buy-Budget.png`} width="158px" height="111px"/>
          </PSXImageContainer>
        </PSXContainer>
      </Footer>
    </Column>
  )

};

// export default withTheme(ThankYouPage);
const ThankYou = withTheme(ThankYouPage);
export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
