// const branchIdentifier = process.env.BRANCH_IDENTIFIER || 'develop';
const branchIdentifier = process.env.BRANCH_IDENTIFIER || 'master';
const appConfig = process.env.CONFIG || 'nonprod';
const nodeEnv = process.env.NODE_ENV || '';
const babelEnv = process.env.BABEL_ENV || '';
const deploymentVersion = process.env.DEPLOYMENT_VERSION;
const isProd = appConfig === 'prod';
const isUsWest2 = process.env.US_WEST_2 || '';
let fdpqApiBaseUrl = '';

if (isUsWest2) {
  if (isProd) {
    fdpqApiBaseUrl = `https://usw2-${branchIdentifier}.awskbbfdpq.kbb.com`;
  } else {
    fdpqApiBaseUrl = `https://usw2-${branchIdentifier}.awskbbfdpqnp.kbb.com`;
  }
} else {
  if (isProd) {
    fdpqApiBaseUrl = `https://use1-${branchIdentifier}.awskbbfdpq.kbb.com`;
  } else {
    fdpqApiBaseUrl = `https://use1-${branchIdentifier}.awskbbfdpqnp.kbb.com`;
  }
}

const commonConfig = {
  appConfig,
  babelEnv,
  branch: branchIdentifier,
  deploymentVersion,
  nodeEnv,
  useCdn: isProd,
  fdpqApiBaseUrl: fdpqApiBaseUrl,
  fdpqCdnBaseUrl: isProd ? 'https://fdpq.syndication.kbb.com' : 'https://dev.awskbbfdpqnp.kbb.com',
  fdpqFileCdnBaseUrl: isProd ? 'https://file.awskbbfdpq.kbb.com' : 'https://file.awskbbfdpqnp.kbb.com',
  psxCTAUrl: isProd ? 'https://www.autotrader.com/sell-my-car' : 'https://test.autotrader.com/sell-my-car', 
  gtmCode: isProd ? 'GTM-KQQM5F8' : 'GTM-M23PMMN',
  isUsWest2: isUsWest2
};

export default commonConfig;
