import { PAGE_NAME, getPixallEventData } from '@packages/fdpq-core/constants/app';
import {
  ADD_SELECTED_DEALER,
  REMOVE_SELECTED_DEALER,
  ADD_MULTIPLE_SELECTED_DEALER,
  UPDATE_DEALER_LIST,
  CLEAR_SELECTED_DEALERS,
  CLICK_DEALER_NEXT_BUTTON,
} from '../action-types/dealers';
import {
  MORE_THAN_3_DEALER_SELECTED,
  NO_DEALER_SELECTED,
} from '../constants/errorCodes';
import { clearSelectedMake, clearSelectedYear } from './vehicles';
import {
  toVehicleSelector,
  toContactForm,
} from './navigation';
import {
  removeErrors,
  emptyDealerListError,
  moreThan3DealerSelectedError,
  noDealerSelectedError,
} from './errors';
import { getDealerList } from './bycClient';
import { fireFloodLightTag } from '../utils/floodlightUtils';
import { getActionTypes } from '../utils/vehicleStateUtil';
import { getPreselectedDealers } from '../utils/dealerUtil';
import { sendEventPixall, createCoxAutoCorrelationId } from '@packages/fdpq-common/analytics/pixall';

export const preselectDealers = () => (dispatch, getState) => {
  try {
    const {
      dealers: { dealerList },
    } = getState();
  
    const preselectedDealers = getPreselectedDealers(dealerList);
    dispatch({
      type: ADD_MULTIPLE_SELECTED_DEALER,
      data: preselectedDealers,
    });
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
};

export const initDealerSelectorPage = (
  selectedDealers,
  initialDealerList = [],
) => async (dispatch, getState) => {
  dispatch(removeErrors([MORE_THAN_3_DEALER_SELECTED, NO_DEALER_SELECTED]));
  if (initialDealerList && initialDealerList.length === 0) {
    const { vehicles: { selectedVehicle }, location, app } = getState();
    const { makeName, modelName, trimName, yearId } = selectedVehicle;
    const { config: { hostPageName, listingTypeConfig: { vehicleListingType } } } = app;
    const dealerList = await dispatch(getDealerList({
      vehicleMake: makeName,
      vehicleModel: modelName,
      vehicleTrim: trimName,
      vehicleYear: yearId,
      zipCode: location.zipcode,
      vehicleListingType,
      hostPageName,
    }));
    if (dealerList && dealerList.data && dealerList.data.length > 0) {
      dispatch({
        type: UPDATE_DEALER_LIST,
        data: dealerList.data,
      });
      dispatch(preselectDealers());
      dispatch(fireFloodLightTag());
    } else if (dealerList && dealerList.error) {
      // we get in here if the BYC endpoint returns a non 200 response.
      const showError = false;
      dispatch(emptyDealerListError(showError)); // Current logic is to not display error message if no dealers if the initial page load is on dealer select page instead of vehicle select
      dispatch(clearSelectedMake());
      dispatch(toVehicleSelector());
    } else {
      // we get here when there is 200 response from BYC endpoint and we receive an empty dealer list
      const actionTypes = getActionTypes(PAGE_NAME.DEALER_SELECTOR);
      dispatch(toVehicleSelector());
      dispatch(clearSelectedYear(actionTypes));
      dispatch(clearSelectedMake());
    }
  }
};

export const clearSelectedDealers = () => ({
  type: CLEAR_SELECTED_DEALERS,
});

export const selectDealer = selectedDealer => (dispatch, getState) => {
  if (!selectedDealer.isSelected) {
    dispatch(removeErrors([MORE_THAN_3_DEALER_SELECTED]));
    return dispatch({
      type: REMOVE_SELECTED_DEALER,
      data: selectedDealer,
    });
  }
  
  const { dealers } = getState();
  if (Object.keys(dealers.selectedDealers).length >= 3) {
    return dispatch(moreThan3DealerSelectedError());
  }
  dispatch(removeErrors([NO_DEALER_SELECTED]));

  return dispatch({
    type: ADD_SELECTED_DEALER,
    data: selectedDealer,
  });
};

export const submitDealerForm = (e, isKbb) => (dispatch, getState) => {
  try {
    const { dealers, vehicles: { selectedVehicle }, location, app } = getState();
    const { makeName, modelName, yearId, trimId, trimName } = selectedVehicle;
    const { config: { listingTypeConfig: { vehicleListingType } } } = app;

    var pixallData = getPixallEventData(vehicleListingType);
    const eventData = {
      eventType: pixallData.EVENT_TYPE_CLICK,
      eventSource: pixallData.EVENT_SOURCE,
      label: pixallData.LABEL_STEP_CLICK,
      assistChannel: pixallData.ASSIST_CHANNEL,
      assistProduct: pixallData.ASSIST_PRODUCT,
      assistSubchannel: pixallData.ASSIST_SUBCHANNEL,
      pageType: pixallData.PAGE_TYPE,
      pageId: pixallData.PAGE_ID,
    };
    
    const vehicleData = { 
      vehicleType: vehicleListingType, 
      year: yearId.toString(),
      make: makeName,
      model: modelName,
      zipCode: location?.zipcode,
      chromeStyleId: trimId?.toString(),
      bodyStyle: trimName,
    };

    const coxAutoCorrelationId = createCoxAutoCorrelationId();

    if (Object.keys(dealers.selectedDealers).length === 0) {
      dispatch(noDealerSelectedError());
    } else {
      sendEventPixall(coxAutoCorrelationId, eventData, vehicleData);
      
      dispatch(removeErrors([MORE_THAN_3_DEALER_SELECTED]));

      // dispatch action to navigate to Step 3
      dispatch(toContactForm());
      
      // US463020 - change floodlight behaviors
      dispatch(fireFloodLightTag());
    }
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
};

export const clickDealerNextButton = () => (dispatch) => {
  dispatch({
    type: CLICK_DEALER_NEXT_BUTTON,
  });
};