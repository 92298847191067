/** @jsx jsx */
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { Fragment } from 'react';
import { jsx, css } from '@emotion/core';
import { withTheme } from 'emotion-theming';
import {
  Button,
  Description,
  Dropdown,
  FlexContainer,
  FlexRow,
  FlexCell,
  FloatingLabelTextField,
  Column,
  LoadingModal,
  VehicleInfo,
} from '@packages/fdpq-common';
import { mapStateToProps, mapDispatchToProps, formikProps } from '../containers/VehicleSelector';
import { VEHICLE_LISTING_TYPE, DROPDOWN_TYPE } from '@packages/fdpq-core/constants/app';
import KeylineContainer from '../../fdpq-common/components/KeylineContainer';

const VehicleSelector = ({
  // formik props
  touched,
  errors,
  handleChange,
  handleSubmit,
  setFieldValue,
  // regular props
  viewOptions,
  listingTypeConfig,
  yearOptions,
  makeOptions,
  modelOptions,
  trimOptions,
  selectedYear,
  selectedMake,
  selectedModel,
  selectedTrim,
  stateErrors,
  zipcode,
  onYearDropDownChange,
  onMakeDropDownChange,
  onModelDropDownChange,
  onTrimDropDownChange,
  onButtonPress,
  theme,
  leadpartnerfranchiseid,
  leadFranchiseId
}) => {
  // Define boolean
  const hasError = (stateErrors && stateErrors.EMPTY_DEALERLIST && stateErrors.EMPTY_DEALERLIST.showError) || (stateErrors && stateErrors.API_ERROR);
  const hasEmptyDealerlistError = stateErrors && stateErrors.EMPTY_DEALERLIST && stateErrors.EMPTY_DEALERLIST.showError;
  const hasApiError = stateErrors && stateErrors.API_ERROR;

  // Define var for Description title and detail
  const emptyDealerlistTitle = stateErrors && stateErrors.EMPTY_DEALERLIST && stateErrors.EMPTY_DEALERLIST.descriptionTitle;
  const ApiErrorTitle = stateErrors && stateErrors.API_ERROR && stateErrors.API_ERROR.descriptionTitle;
  const emptyDealerlistDetail = stateErrors && stateErrors.EMPTY_DEALERLIST && stateErrors.EMPTY_DEALERLIST.descriptionDetail;
  const ApiErrorDetail = stateErrors && stateErrors.API_ERROR && stateErrors.API_ERROR.descriptionDetail;
  const defaultTitle = viewOptions.description.title;
  const defaultSubtitleText = !viewOptions.hideSubtitle ? viewOptions.description.text : null;
  const dropDownOptions = viewOptions.vehicleSelectorDropDowns;

  const isTradeIn = (listingTypeConfig.vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN);

  let titleText = defaultTitle;
  let subtitleText = defaultSubtitleText;
  if (hasEmptyDealerlistError) {
    titleText = emptyDealerlistTitle;
    subtitleText = emptyDealerlistDetail;
  } else if (hasApiError) {
    titleText = ApiErrorTitle;
    subtitleText = ApiErrorDetail;
  }
  const submitVehicleButton = (leadpartnerfranchiseid.length === 0 && leadFranchiseId.length === 0) ? viewOptions.submitVehicleButton.text : viewOptions.submitVehicleButton.textHaveFranchiseId;
  const yearDropdown = (<Dropdown
    disabled={false}
    error={errors.modelDropdown && !selectedYear}
    handleDropdownChange={setFieldValue}
    updateState={onYearDropDownChange}
    items={yearOptions}
    name={'yearDropdown'}
    placeholderText={'Year'}
    value={selectedYear}
  />);
  const makeDropdown = (<Dropdown
    disabled={(listingTypeConfig.vehicleSelectorView.disableMake && !selectedYear) || (makeOptions.length <= 0)}
    error={errors.modelDropdown && !selectedMake}
    handleDropdownChange={setFieldValue}
    updateState={onMakeDropDownChange}
    items={makeOptions}
    name={'makeDropdown'}
    placeholderText={'Make'}
    value={selectedMake}
  />);
  const modelDropdown = (<Dropdown
    disabled={!selectedMake}
    error={!selectedMake || (errors.modelDropdown && !selectedModel)}
    handleDropdownChange={setFieldValue}
    updateState={onModelDropDownChange}
    items={modelOptions}
    name={'modelDropdown'}
    placeholderText={'Model'}
    value={selectedModel}
  />);
  const trimDropdown = (<Dropdown
    disabled={!selectedModel}
    error={!selectedModel || (errors.trimDropdown && !selectedTrim)}
    handleDropdownChange={setFieldValue}
    updateState={onTrimDropDownChange}
    items={trimOptions}
    name={'trimDropdown'}
    placeholderText={'Style/Trim'}
    value={selectedTrim}
  />);
  const zipField = (<FloatingLabelTextField
    error={(touched.zipInput && errors.zipInput) || (stateErrors.EMPTY_DEALERLIST && stateErrors.EMPTY_DEALERLIST.showError)}
    inputText={zipcode}
    labelText={'ZIP'}
    maxLength={5}
    type="tel"
    name={'zipInput'}
    numeric={true}
    onChange={handleChange}
  />);
  const submitButton = (<Button
    type="submit"
    name={viewOptions.submitVehicleButton.name}
    onClick={(e) => { onButtonPress(); handleSubmit(e); }}
  >{submitVehicleButton}</Button>);

  return (
    <Column id={viewOptions.id}>
      <LoadingModal loadingSubscriptions={['getDealerList']} />
      <FlexContainer>
        <KeylineContainer style={{ paddingBottom: isTradeIn ? '0px' : '16px' }}>
          <FlexRow fullWidth={true}>
            {(viewOptions.showVehicleInfo)
              ? <FlexCell id="test-tradein-vehicleinfo" fullWidth={true} columnSpan={{ lg: 6, md: 6, sm: 12 }}>
                <VehicleInfo theme={theme} heading='TRADEIN' />
              </FlexCell>
              : <Fragment />
            }
            <FlexCell fullWidth={isTradeIn} columnSpan={{ lg: 6, md: 6, sm: 12 }}>
              <Description error={hasError} title={titleText} subtitle={subtitleText} />
            </FlexCell>
          </FlexRow>
        </KeylineContainer>
        <FlexRow style={{ marginTop: isTradeIn ? '0px' : '8px' }}>
          <FlexCell columnSpan={{ lg: 6, md: 6, sm: 12 }}>
            {dropDownOptions.includes(DROPDOWN_TYPE.YEAR) ? yearDropdown : makeDropdown}
          </FlexCell>
          <FlexCell columnSpan={{ lg: 6, md: 6, sm: 12 }}>
            {dropDownOptions.includes(DROPDOWN_TYPE.YEAR) ? makeDropdown : modelDropdown}
          </FlexCell>
        </FlexRow>
        <FlexRow>
          <FlexCell columnSpan={{ lg: 6, md: 6, sm: 12 }}>
            {dropDownOptions.includes(DROPDOWN_TYPE.TRIM) ? trimDropdown : dropDownOptions.length < 3 ? zipField : modelDropdown}
          </FlexCell>
          {(isTradeIn) ?
            <FlexCell columnSpan={{ lg: 6, md: 6, sm: 12 }}>
              {submitButton}
            </FlexCell>
            :
            <FlexCell columnSpan={{ lg: 6, md: 6, sm: 12 }}>
              <FlexRow>
                <FlexCell columnSpan={{ lg: 6, md: 6, sm: 6 }}>
                  {zipField}
                </FlexCell>
                <FlexCell columnSpan={{ lg: 6, md: 6, sm: 6 }}>
                  {submitButton}
                </FlexCell>
              </FlexRow>
            </FlexCell>
          }
        </FlexRow>
      </FlexContainer>
    </Column>
  );
};

const VehicleSelectorWithValidation = withFormik(formikProps)(withTheme(VehicleSelector));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleSelectorWithValidation);
