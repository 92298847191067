import { SET_ERROR, REMOVE_ERROR } from '../action-types/errors';
import FdpqLead from '../../fdpq-contact-form/containers/FdpqLead';
import {
  CONTACT_FORM_ERRORS,
  UPDATE_CONTACT_INFO,
  CLICK_CONTACT_SUBMIT_BUTTON,
} from '../action-types/contact';
import { SUMBIT_PIXALL_ID } from '../action-types/pixall';
import { toThankYouPage, toVehicleSelector } from './navigation';
import { validateLead, submitLead } from './bycClient';
import { fireFloodLightTag } from '../utils/floodlightUtils';
import { sendEventPixall, createCoxAutoCorrelationId } from '@packages/fdpq-common/analytics/pixall';
import { getPixallEventData } from '../constants/app';

const pixallIds = {
  pixallPrimaryId: null,
  pixallSecondaryId: null,
};

// Contact Action Creators
export const submitContactInfo = (contactInfo) => {
  return async (dispatch, getState) => {
    try {
      // call out to validate lead to validate contact form data
      const data = {
        firstName: contactInfo.firstName,
        lastName: contactInfo.lastName,
        email: contactInfo.email,
        phone: contactInfo.phone,
      };
      dispatch({
        type: UPDATE_CONTACT_INFO,
        data,
      });
      const validationResponse = await dispatch(validateLead(data));
      if (validationResponse && validationResponse.data && validationResponse.data.successful) {
        // clear contact form errors from state.
        dispatch({
          type: REMOVE_ERROR,
          data: CONTACT_FORM_ERRORS,
        });

        if (pixallIds.pixallPrimaryId || pixallIds.pixallSecondaryId) {
          dispatch({
            type: SUMBIT_PIXALL_ID,
            data: pixallIds,
          });
          console.log('The Pixall Ids are passed !');
          console.log(`Primary id: ${pixallIds.pixallPrimaryId} - Secondary id: ${pixallIds.pixallSecondaryId}`);
        }

        // Submit lead
        const leadData = new FdpqLead(getState());
        const coxAutoCorrelationId = createCoxAutoCorrelationId();
        const submissionResponse = await dispatch(submitLead(leadData, coxAutoCorrelationId));

        const pixallData = getPixallEventData(leadData.vehicleListingType);
        const eventData = {
          eventType: pixallData.EVENT_TYPE_CLICK,
          eventSource: pixallData.EVENT_SOURCE,
          label: pixallData.LABEL_STEP_CONTACT,
          assistChannel: pixallData.ASSIST_CHANNEL,
          assistProduct: pixallData.ASSIST_PRODUCT,
          assistSubchannel: pixallData.ASSIST_SUBCHANNEL,
          pageType: pixallData.PAGE_TYPE,
          pageId: pixallData.PAGE_ID,
          eventFields: {
            goal: pixallData.EVENT_FIELD_GOAL,
          }
        };

        const vehicleData = { 
          vehicleType: leadData.vehicleListingType, 
          year: leadData.year?.toString(),
          make: leadData.makeName,
          model: leadData.modelName,
          zipCode: leadData?.entryZip,
          chromeStyleId: leadData.vehicleId?.toString(),
          bodyStyle: leadData.trimName,
          valueEventCode: pixallData.CDL_VALUE_EVENT_CODE,
        };

        sendEventPixall(coxAutoCorrelationId, eventData, vehicleData);
        if (submissionResponse && submissionResponse.data) {
          // dispatch action to navigate to Step 4
          dispatch(toThankYouPage());
          dispatch(fireFloodLightTag());
        } else {
          dispatch(toVehicleSelector());
        }
      } else if (!validationResponse.data) {
        dispatch(toVehicleSelector());
      } else {
        const errors = validationResponse.data.failures.reduce(
          (acc, failure) => {
            switch (failure.field) {
              case 'Firstname':
                acc.firstName = true;
                break;
              case 'Lastname':
                acc.lastName = true;
                break;
              case 'Email':
                acc.email = true;
                break;
              case 'Phone':
                acc.phone = true;
                break;
              default:
                break;
            }
            return acc;
          },
          { errorCode: 'CONTACT_FORM_ERRORS' }
        );

        dispatch({
          type: SET_ERROR,
          data: errors,
        });
      }
    } catch (e) {
      dispatch(toVehicleSelector());
      console.error(e); // eslint-disable-line no-console
    }
  };
};

export const getPixallIdsPostMessage = (event) => {
  if (
    event.data
    && event.data.type
    && event.data.type === 'onSubmitPixallIds'
  ) {
    pixallIds.pixallPrimaryId = event.data.pixallPrimaryId;
    pixallIds.pixallSecondaryId = event.data.pixallSecondaryId;
  }
};

export const clickContactSubmitButton = () => {
  return (dispatch) => {
    dispatch({
      type: CLICK_CONTACT_SUBMIT_BUTTON,
    })
  }
};