import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { css } from '@emotion/core';
import FlexBox from '@argo/principles/FlexBox';
import Button from '@argo/ui-elements/Button';
import Dropdown from '@argo/ui-elements/Dropdown';
import { InnerLabelInputField } from '@argo/ui-elements/InputField';
import Heading from '@argo/ui-elements/Heading';
import Paragraph from '@argo/ui-elements/Paragraph';
import { LoadingModal, VehicleInfoArgo } from '@packages/fdpq-common';
import { focusState } from '@argo/utils';
import ContentBlock from '@argo/principles/ContentBlock';
import { useAdaptiveRender } from '@argo/utils';
import breakpoints from '@argo/principles/constants/breakpoints';
import size from '@argo/principles/constants/size';
import { VEHICLE_LISTING_TYPE, DROPDOWN_TYPE } from '@packages/fdpq-core/constants/app';
import isDealerDetailsPage from '@packages/fdpq-core/utils/page';
import { mapStateToProps, mapDispatchToProps, formikProps } from '../containers/VehicleSelector';

const VehicleSelectorArgo = ({
  // formik props
  touched,
  errors,
  handleSubmit,
  setFieldValue,
  // regular props
  viewOptions,
  listingTypeConfig,
  yearOptions,
  makeOptions,
  modelOptions,
  trimOptions,
  selectedYear,
  selectedMake,
  selectedModel,
  selectedTrim,
  stateErrors,
  zipcode,
  onYearDropDownChange,
  onMakeDropDownChange,
  onModelDropDownChange,
  onTrimDropDownChange,
  onButtonPress,
  theme,
  leadpartnerfranchiseid,
  leadFranchiseId,
}) => {
  // Define boolean
  const hasError = (stateErrors && stateErrors.EMPTY_DEALERLIST && stateErrors.EMPTY_DEALERLIST.showError) || (stateErrors && stateErrors.API_ERROR);
  const hasEmptyDealerlistError = stateErrors && stateErrors.EMPTY_DEALERLIST && stateErrors.EMPTY_DEALERLIST.showError;
  const hasApiError = stateErrors && stateErrors.API_ERROR;

  // Define var for Description title and detail
  const emptyDealerlistTitle = stateErrors && stateErrors.EMPTY_DEALERLIST && stateErrors.EMPTY_DEALERLIST.descriptionTitle;
  const ApiErrorTitle = stateErrors && stateErrors.API_ERROR && stateErrors.API_ERROR.descriptionTitle;
  const emptyDealerlistDetail = stateErrors && stateErrors.EMPTY_DEALERLIST && stateErrors.EMPTY_DEALERLIST.descriptionDetail;
  const ApiErrorDetail = stateErrors && stateErrors.API_ERROR && stateErrors.API_ERROR.descriptionDetail;
  const defaultTitle = viewOptions.description.title;
  const defaultSubtitleText = !viewOptions.hideSubtitle ? viewOptions.description.text : null;
  const dropDownOptions = viewOptions.vehicleSelectorDropDowns;

  const isTradeIn = (listingTypeConfig.vehicleListingType === VEHICLE_LISTING_TYPE.TRADEIN);

  let titleText = defaultTitle;
  let subtitleText = defaultSubtitleText;
  if (hasEmptyDealerlistError) {
    titleText = emptyDealerlistTitle;
    subtitleText = emptyDealerlistDetail;
  } else if (hasApiError) {
    titleText = ApiErrorTitle;
    subtitleText = ApiErrorDetail;
  }

  const { width } = useAdaptiveRender();
  const isSmallOrMediumViewPort = width < breakpoints.lg;
  const submitText = viewOptions.submitVehicleButton.text;

  const errorDropdownStyles = css`
    select {
      border: 1px solid ${theme.dropdown.errorBorderColor};
      border-radius: ${theme.dropdown.errorBorderRadius};
      transition: border 0.25s;
    }
  `;

  const errorTextStyles = css`
    color: ${theme.text.errorColor} !important;
  `;

  const defaultStyles = css`  
    ${focusState};
    @media only screen and (max-width: ${breakpoints.lg}px) {
      font-size: ${size.sm}px;
    }
  `;

  const DropdownStyle = css`
    select {
      display: inline-block;
    }

    @-moz-document url-prefix() {
      select {
        text-indent: 2px;
      }
    }
  `;

  const submitVehicleButton = (leadpartnerfranchiseid.length === 0 && leadFranchiseId.length === 0) ? viewOptions.submitVehicleButton.text : viewOptions.submitVehicleButton.textHaveFranchiseId;
  const [currentZipcode, setCurrentZipcode] = useState(zipcode);

  const yearDropdown = (<Dropdown
    disabled={false}
    css={errors.modelDropdown && !selectedYear ? errorDropdownStyles : DropdownStyle}
    onChangeValue={setFieldValue}
    items={yearOptions}
    onChange={(event) => {
      onYearDropDownChange(yearOptions.find(x => x.text === event));
    }}
    id='yearDropdown'
    className='yearDropdown'
    placeholder='Year'
    value={selectedYear}
  />);

  const makeDropdown = (<Dropdown
    disabled={(listingTypeConfig.vehicleSelectorView.disableMake && !selectedYear)  || (makeOptions.length <= 0)}
    css={(errors.modelDropdown && !selectedMake) ? errorDropdownStyles : DropdownStyle}
    items={makeOptions}
    onChange={(event) => {
      const kbbMakeId = parseInt(event, 10);
      onMakeDropDownChange(makeOptions.find(x => x.value === kbbMakeId));
    }}
    id='makeDropdown'
    className='makeDropdown'
    placeholder='Make'
    value={selectedMake}
  />);

  const modelDropdown = (<Dropdown
    disabled={!selectedMake}
    css={(selectedMake && errors.modelDropdown && !selectedModel) ? errorDropdownStyles : DropdownStyle}
    items={modelOptions}
    onChange={(event) => {
      const kbbModelId = parseInt(event, 10);
      onModelDropDownChange(modelOptions.find(x => x.value === kbbModelId));
    }}
    id='modelDropdown'
    className='modelDropdown'
    placeholder='Model'
    value={selectedModel || ''}
  />);

  const trimDropdown = (<Dropdown
    disabled={!selectedModel}
    css={(selectedModel && errors.trimDropdown && !selectedTrim) ? errorDropdownStyles : DropdownStyle}
    items={trimOptions}
    onChange={(event) => {
      const kbbTrimId = parseInt(event, 10);
      onTrimDropDownChange(trimOptions.find(x => x.value === kbbTrimId));
    }}
    id='trimDropdown'
    className='trimDropdown'
    placeholder='Style/Trim'
    value={selectedTrim || ''}
  />);

  const zipField = (<div><InnerLabelInputField
    disable={false}
    data-input-type="Zipcode"
    error={(touched.zipInput && errors.zipInput) || (stateErrors.EMPTY_DEALERLIST && stateErrors.EMPTY_DEALERLIST.showError)}
    id="zipInput"
    labelText="ZIP"
    mask="00000"
    maskPlaceholder=""
    maskType={null}
    maxLength={null}
    placeholder=" "
    requiredText="*This text is required"
    validationType={null}
    width={`calc(100% - ${size.sm}px)`}
    name='zipInput'
    value={currentZipcode}
    css={defaultStyles}
    onChange={(event) => {
      const zip = event.target.value;
      setCurrentZipcode(zip);
      setFieldValue('zipInput', zip);
    }}
  /></div>);

  const submitButton = (<div><Button
    name={viewOptions.submitVehicleButton.name}
    id={viewOptions.submitVehicleButton.name}
    buttonType='primary'
    onClick={(e) => { onButtonPress(); handleSubmit(e); }}
    text={submitVehicleButton}
    width='100%'
    minimizeSize={false}
    disabled={false}
    href='#'
    target='_self'
    enableCapitalize={true}
    sizeType='regular'
    type='submit'
  /></div>);

  const flexBoxDefault = [
    '1 0 100%',
    '1 0 100%',
    '1 0 100%',
    '1 0 100%',
    '1 0 100%',
    '1 0 40%',
    '0 0 60%',
  ];

  const flexBoxMobileTablet = [
    '1 0 100%',
    '1 0 100%',
    '1 0 100%',
    '1 0 100%',
    '1 0 100%',
    '1 0 50%',
    '0 0 50%',
  ];

  const flexBoxDesktop = [
    '1 0 100%',
    '1 0 100%',
    '0 0 50%',
    '0 0 50%',
    '0 0 50%',
    '1 0 25%',
    '0 0 25%',
  ];

  return (
    <FlexBox
      alignItems="flex-start"
      default={flexBoxDefault}
      xs={flexBoxMobileTablet}
      sm={flexBoxMobileTablet}
      md={flexBoxMobileTablet}
      lg={flexBoxDesktop}
      xl={flexBoxDesktop}
      max={flexBoxDesktop}
      flexWrap="wrap"
      horizontalSpacing={isSmallOrMediumViewPort ? 'sm' : 'md'}
      id={viewOptions.id}
    >
      <LoadingModal loadingSubscriptions={['getDealerList']} useArgoStyle={true} />
      <ContentBlock verticalSpacing="xs">
        {(viewOptions.showVehicleInfo)
          ? <div id="test-tradein-vehicleinfo">
            <VehicleInfoArgo heading='TRADEIN' />
          </div>
          : <Fragment />
        }
        <div>
          <Heading
            headingName='ContentHeadingExtra'
            headingTag="h2"
            textTransformation={true}
            enableTitleCase={false}
            css={hasError ? errorTextStyles : null}
            id="description-title"
          >
            {titleText}
          </Heading>
        </div>
        <div>
        </div>
        {!isDealerDetailsPage &&
          <div>
           <Paragraph
             defaultMargin={true}
             textAlign="left"
             type="body"
             css={hasError ? errorTextStyles : null}
             id="description-subtitle"
           >
             {subtitleText}
           </Paragraph>
         </div>
        }
      </ContentBlock>
      {(dropDownOptions.includes(DROPDOWN_TYPE.YEAR)) ? yearDropdown : makeDropdown}
      {(dropDownOptions.includes(DROPDOWN_TYPE.YEAR)) ? makeDropdown : modelDropdown}
      {(dropDownOptions.includes(DROPDOWN_TYPE.TRIM)) ? trimDropdown : dropDownOptions.length < 3 ? zipField : modelDropdown}
      {(!isTradeIn) ? zipField : ''}
      {submitButton}
    </FlexBox>
  );
};

const VehicleSelectorWithValidation = withFormik(formikProps)(VehicleSelectorArgo);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleSelectorWithValidation);
