/** @jsx jsx */
import { useEffect  } from 'react';
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import FlexBox from '@argo/principles/FlexBox';
import { withTheme } from 'emotion-theming';
import Button from '@argo/ui-elements/Button';
import ContentBlock from '@argo/principles/ContentBlock';
import Heading from '@argo/ui-elements/Heading';
import Paragraph from '@argo/ui-elements/Paragraph';
import Image from '@argo/ui-elements/Image';
import { connect } from 'react-redux';
import { useAdaptiveRender } from '@argo/utils';
import Breakpoints from '@argo/principles/constants/breakpoints';
import DealerList from '../../fdpq-dealer-selector/components/DealerListArgo';

import { text } from '../../fdpq-core/config/vars';
import { mapStateToProps, mapDispatchToProps } from '../containers/ThankYouPage';
import { getPixallEventData } from '@packages/fdpq-core/constants/app';
import { sendEventPixall, createCoxAutoCorrelationId } from '@packages/fdpq-common/analytics/pixall';
import commonConfig from '@packages/fdpq-core/config/common';

const ThankYouPageArgo = ({
  onNextPageSelected,
  onButtonPress,
  selectedDealers,
  listingTypeConfig,
  leadpartnerfranchiseid,
  leadFranchiseId,
  zipCode,
}) => {

  const flexBoxDefault = [
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 50%',
    '0 0 50%',
    '0 0 100%',
  ];

  const flexBoxMobileTablet = [
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 50%',
    '0 0 50%',
    '0 0 100%',
  ];

  const flexBoxDesktop = [
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 100%',
    '0 0 50%',
    '0 0 50%',
    '0 0 25%',
    '0 0 25%',
    '0 0 100%',
  ];

  const PSXContainer = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 480px) {
      flex-direction: column;
  }
  `;

  const PSXImageContainer = styled.div`
    margin-left: 40px;

    @media (max-width: 480px) {
      margin-left: 0px;
  `;

  const { width } = useAdaptiveRender();
  const isSmallOrMediumViewPort = width < Breakpoints.lg;
  const coxAutoCorrelationId = createCoxAutoCorrelationId();
  const pixallData = getPixallEventData(listingTypeConfig.vehicleListingType);

  const eventData = {
    eventSource: pixallData.EVENT_SOURCE,
    eventType: pixallData.EVENT_TYPE_IMPRESSION,
    label: pixallData.LABEL_STEP_IMPRESSION,
    assistChannel: pixallData.ASSIST_CHANNEL,
    assistProduct: pixallData.ASSIST_PRODUCT,
    assistSubchannel: pixallData.ASSIST_SUBCHANNEL,
    pageType: pixallData.PAGE_TYPE,
    pageId: pixallData.PAGE_ID,
  }

  const psxPixallData = getPixallEventData(listingTypeConfig.vehicleListingType, true);
  const psxEventData = {
    eventType: psxPixallData.EVENT_TYPE_CLICK,
    eventSource: psxPixallData.EVENT_SOURCE,
    label: psxPixallData.PSX_LABEL,
    feature: psxPixallData.FEATURE,
    assistChannel: psxPixallData.ASSIST_CHANNEL,
    pageType: pixallData.PAGE_TYPE,
    pageId: pixallData.PAGE_ID,
  }

  const vehicleData = { 
    zipCode,
  };

  const getAnotherQuoteButton = (
    <Button
      buttonType='tertiary'
      onClick={(e) => {
        onButtonPress();
        onNextPageSelected(e);
      }}
      text={text.global.thankYouView.getAnotherQuoteText}
      sizeType='large'
      width='236px'
      height='48px'
      style={!isSmallOrMediumViewPort ? { float: 'left' } : { float: 'center' }}
    />
  )

  useEffect(() => {
    sendEventPixall(coxAutoCorrelationId, eventData, vehicleData);
  }, []);

  const handleSellCarClick = () => {
    try {
      if (window.self !== window.top) {
        // if in an iframe, redirect the parent window
        window.parent.location.href = commonConfig.psxCTAUrl;
      } else {
        // if not in an iframe, redirect current window
        window.location.href = commonConfig.psxCTAUrl;
      }
      
      sendEventPixall(coxAutoCorrelationId, psxEventData, vehicleData);
    } catch (error) {
      console.error('Navigation error:', error);
      window.location.href = commonConfig.psxCTAUrl;
    }
  }

  return (
    <FlexBox
      alignItems="flex-start"
      default={flexBoxDefault}
      xs={flexBoxMobileTablet}
      sm={flexBoxMobileTablet}
      md={flexBoxMobileTablet}
      lg={flexBoxDesktop}
      xl={flexBoxDesktop}
      max={flexBoxDesktop}
      flexWrap="wrap"
      horizontalSpacing={isSmallOrMediumViewPort ? 'sm' : 'md'}
      id="test-thank-you-page"
    >
      <ContentBlock verticalSpacing="xs">
        <div>
          <Heading
            enableTitleCase
            headingName="ContentHeadingExtra"
            headingTag="h2"
            textTransformation={true}
            id="description-title"
          >
            {text.global.thankYouView.titleText}
          </Heading>
        </div>
        <div>
          <Paragraph
            defaultMargin={true}
            textAlign="left"
            type="body"
            id="description-subtitle"
          >
            {text.global.thankYouView.subtitleText}
          </Paragraph>
        </div>
      </ContentBlock>
      <DealerList displayDealerList={selectedDealers} />
      <div>
        {(leadFranchiseId.length === 0 && leadpartnerfranchiseid.length === 0) ? getAnotherQuoteButton : ''}
      </div>
      <hr />
      <PSXContainer>
          <div>
            <Heading headingTag="h2" headingName="ContentHeadingExtra" enableBakeTitle={false}>
              {text.global.thankYouView.psxTitleText}
            </Heading>
            <Paragraph
              defaultMargin={true}
              textAlign="left"
              type="body"
              id="description-subtitle"
              style={{ lineHeight: '24px', marginBottom: '8px'}}
            >
              {text.global.thankYouView.psxSubTitleText}
            </Paragraph>
            <Button
                onClick={handleSellCarClick}
                text={text.global.thankYouView.sellCurrentCarText}
                sizeType='regular'
                width='258px'
                height='48px'
                style={!isSmallOrMediumViewPort ? { float: 'left' } : { float: 'center' }}
            />
          </div>
          <PSXImageContainer>
            <Image src={`${commonConfig.fdpqFileCdnBaseUrl}/Value-SellOrTradeWithConfidence.png`} width="135px"/>
          </PSXImageContainer>
      </PSXContainer>
    </FlexBox>
  );
};

// export default withTheme(ThankYouPageArgo);
const ThankYou = withTheme(ThankYouPageArgo);
export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);
